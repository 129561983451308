import { Link } from "react-router-dom"

const Header = () => {
  return (
    <header>
      {/* TO DO ADD LOGO */}
     <Link className='Link' to='/'><h1 className='logotext'>Project ✘</h1></Link>
      <nav>
        <ul>
          <li><Link className='Link' to='/'>Home</Link></li>
          <li><Link className='Link' to='/projects'>Projects</Link></li>
          <li><Link className='Link' to='/about'>About Us</Link></li>
          <li><Link className='Link' to='/contact'>Contact</Link></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
