const Project = ({ title, descr, path, image }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{descr}</p>
      <button>Go to project</button>
    </div>
  )
}

Project.defaultProps ={
  title: 'Title',
  descr: 'Description'
}

export default Project
