const Footer = () => {
  return (
    <div>
      <footer>
          <p>Copyright &copy; 2021</p>
      </footer>
    </div>
  )
}

export default Footer
