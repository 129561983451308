const HomePage = () => {
  return (
     <div className='homebg'>

       <button className='homebtn'>Under Construction</button>
     </div>

  )
}

export default HomePage


