import Project from "../components/Project"

const ProjectsPage = () => {
  return (
    <div>
      <h1>Projects Page</h1>
      <Project title="jonathansPH" descr="gekkepics" />
    </div>
  )
}

export default ProjectsPage
